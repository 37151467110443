/* General styling */
body {
  margin: 0;
  font-family: "courier prime", courier;
  background-color: #2774e0; /* Theme blue */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: 2774e0;
  color: 2774e0;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  z-index: 1000;
  
}
.App {
  width: 100%;
  height: 100%;
}

/* Universal screen styling */
.screen {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
}
.header-box {
  text-align: center;
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #2774e0; /* Blue text */
  font-family: "Courier New", Courier, monospace;
}

.header-box h1 {
  margin: 0;
  font-size: 2rem;
}

.header-box h3 {
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  font-weight: 300;
}

/* Login Screen */
.login-screen .login-container {
  background-color: white;
  color: #2774e0;
  padding: 2rem;
  border-radius: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.login-title {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.login-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #1a5bb5;
}

.login-instructions {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 1rem;
}

.login-form {
  width: 100%;
}

/* Input Fields Styling */
.input {
  width: 100%;
  padding: 12px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.input:focus {
  border-color: #2774e0;
  box-shadow: 0 0 6px rgba(39, 116, 224, 0.5);
  outline: none;
}

.login-button {
  background-color: #2774e0;
  color: white;
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-button:hover {
  background-color: #1a5bb5;
}

.login-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.forgot-password-text {
  color: #1a5bb5;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
}

/* Home Screen Button Container */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 90%;
  max-width: 400px;
}

.button-box {
  background-color: white;
  color: #2774e0;
  padding: 1.5rem;
  text-align: center;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.button-box:hover {
  transform: scale(1.03);
  background-color: #f0f0f0;
}

/* Logout Box */
.logout-box {
  background-color: white;
  color: #2774e0;
  padding: 1.5rem;
  text-align: center;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease, border-color 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  border: 2px solid #2774e0;
}

.logout-box:hover {
  transform: scale(1.03);
  background-color: #f0f0f0;
  border-color: #1a5bb5;
}

/* Video Section */
.video-section {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
}
.video-stream {
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container */
  object-fit: cover; /* Ensures the video fits nicely */
  border-radius: 10px; /* Optional: Match the style of the container */
}
.video-stream {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none; /* Prevent user interactions triggering full-screen */
}

.video-section {
  position: relative;
  width: 100%; /* Ensures the video stays within the container */
  height: 50%; /* Adjust as needed */
  overflow: hidden; /* Prevent video overflow */
}

.video-stream:fullscreen {
  display: none; /* Disable fullscreen behavior */
}



.video-stream {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.video-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.live-indicator {
  display: flex;
  align-items: center;
}

.red-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
}

.timer {
  margin-left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

/* Alert Text */
.alert-text {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

/* Map Section */
.map-section {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  margin-bottom: 1rem;
}

/* Control Bar */
.control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.control-button {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.recording-button {
  color: red;
}

.end-call-button {
  background-color: #d9534f;
  border-radius: 10px;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 600px) {
  .screen {
    padding: 1rem;
  }

  .header-box {
    font-size: 0.9rem;
  }

  .button-box {
    font-size: 1rem;
  }

  .end-call-button {
    font-size: 1rem;
  }
}
/* Settings Screen */
.settings-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 90%;
  max-width: 400px;
  margin: 1rem auto;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.settings-item {
  margin-bottom: 1.5rem;
}

.settings-item label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  display: block;
  margin-bottom: 0.5rem;
}

.settings-input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
}

/* Reset Password Button */
.reset-button {
  background-color: #2774e0;
  color: white;
  font-size: 1rem;
  padding: 0.8rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.reset-button:hover {
  background-color: #1a5bb5;
}

/* Action Buttons */
.settings-actions {
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 400px;
  margin-top: 1rem;
}

.save-button {
  background-color: #4caf50;
  color: white;
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #d9534f;
  color: white;
  font-size: 1rem;
  padding: 0.8rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #c9302c;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 600px) {
  .settings-container {
    padding: 1rem;
  }

  .settings-actions {
    flex-direction: column;
    gap: 1rem;
  }
}


.camera-controls {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.flip-camera-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #2774e0;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.flip-camera-button:hover {
  background-color: #1a5bb5;
}

.video-section {
  position: relative;
  width: 100%;
  height: auto;
}
.footer {
  text-align: center;
  padding: 10px;
  background-color: #2774e0;
  color: #f1f1f1;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 11px;
}.red-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin: 10px 0;
}.map-container {
  height: 300px;
  border: 2px solid #007BFF;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.7; }
  100% { transform: scale(1); opacity: 0; }
}

.pulsing-marker {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: blue;
  animation: pulse 2s infinite;
}
.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
button:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}
button:active {
  transform: scale(0.95);
}
.live-indicator {
  display: flex;
  align-items: center;
  gap: 5px;
  color: red;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
button {
  transition: all 0.3s ease;
}
button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
button.sos {
  background: #ff4d4d;
  color: white;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 20px rgba(255, 77, 77, 0.6);
}
button {
  position: relative;
  overflow: hidden;
}
button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.4s ease, height 0.4s ease;
}
button:active:after {
  width: 120px;
  height: 120px;
}
.pulse-glow {
  animation: pulseGlow 2s infinite;
}
@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 10px rgba(255, 77, 77, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 77, 77, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 77, 77, 0.7);
  }
}
.pop-in {
  animation: popIn 0.5s ease;
}
@keyframes popIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #28a745;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.particle-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(20, 20, 50, 0.8), #000);
  overflow: hidden;
}
.particle {
  width: 5px;
  height: 5px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  animation: moveParticles 5s infinite ease-in-out;
}
@keyframes moveParticles {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}
.dynamic-gradient {
  background: linear-gradient(90deg, #ff7eb3, #ff758c, #28a745);
  background-size: 200% 200%;
  animation: gradientMove 5s infinite ease-in-out;
}
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.glass-effect {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  padding: 20px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  background: #ccc;
  border-radius: 25px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background: white;
  border-radius: 50%;
  bottom: 2.5px;
  left: 2.5px;
  transition: transform 0.4s;
}
input:checked + .slider {
  background: #4caf50;
}
input:checked + .slider:before {
  transform: translateX(25px);
}
.realistic-shadow {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.15);
}
.spotlight {
  position: relative;
  overflow: hidden;
}
.spotlight::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease;
}
.spotlight:hover::before {
  transform: translate(-50%, -50%) scale(5);
}
.sparkling-background {
  background: black;
  position: relative;
  overflow: hidden;
}
.sparkling-background::after {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  background: radial-gradient(circle, white, transparent);
  animation: sparkle 3s infinite linear;
}
@keyframes sparkle {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.camera-lens {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, #000 80%, transparent 90%);
  border-radius: 50%;
  animation: lensOpen 1s ease;
}
@keyframes lensOpen {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.glass-container {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  padding: 20px;
}
.settings-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.settings-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
/* Disable text selection */
* {
  user-select: none; /* Prevents text selection */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

/* Disable long-press highlighting */
body {
  -webkit-touch-callout: none; /* Prevents long-press menus on iOS */
  -webkit-tap-highlight-color: transparent; /* Removes tap highlight color */
}