/* General Admin Settings Styling */
.screen.admin-settings {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #2774e0;
    text-align: center;
  }
  
  .header-box {
    background-color: #1a5bb5; /* Matches admin theme */
    color: white; /* Makes text white */
    padding: 25px 50px;
    border-radius: 15px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    width: 90%; /* Ensure responsive behavior */
    max-width: 600px; /* Keeps it contained on larger screens */
  }
  
  .header-box h1,
  .header-box h3 {
    margin: 0;
  }
  
  .header-box h1 {
    font-size: 2rem;
  }
  
  .header-box h3 {
    font-size: 1.2rem;
    font-weight: normal;
  }
  
  /* Settings Buttons Container */
  .settings-button-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 90%; /* Makes buttons responsive */
    max-width: 400px; /* Keeps buttons manageable on larger screens */
  }
  
  .settings-button {
    display: block;
    width: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: white;
    color: #2774e0;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 20px;
    border: 2px solid #2774e0;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .settings-button:hover {
    transform: scale(1.03);
    background-color: #f0f0f0;
    border-color: #1a5bb5;
  }
  
  /* Back Button Styling */
  .back-button {
    margin-top: 20px;
    padding: 1.5rem;
    background-color: white;
    color: #2774e0;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    border: 2px solid #2774e0;
    cursor: pointer;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .back-button:hover {
    transform: scale(1.03);
    background-color: #f0f0f0;
    border-color: #1a5bb5;
  }
  
  /* Mobile-Responsive Design */
  @media (max-width: 768px) {
    .header-box {
      padding: 20px;
      font-size: 0.9rem;
    }
  
    .settings-button-container {
      gap: 20px;
    }
  
    .settings-button {
      font-size: 1rem;
      padding: 1rem;
    }
  
    .back-button {
      font-size: 1rem;
      padding: 1rem;
    }
  }