.admin-user-management {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #2774e0;
    color: white;
    font-family: "Courier New", monospace;
    padding: 20px;
    text-align: center;
  }
  
  .header-box {
    background-color: #1a5bb5;
    color: white;
    padding: 25px 40px;
    border-radius: 15px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
  }
  
  .header-box h1 {
    margin: 0;
    font-size: 2rem;
  }
  
  .header-box p {
    margin: 10px 0 0;
    font-size: 1.1rem;
    font-weight: 300;
  }
  
  .user-table-container {
    width: 100%;
    max-width: 900px;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
    overflow-x: auto;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .user-table th,
  .user-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    font-size: 14px;
    color: #333;
  }
  
  .user-table th {
    background-color: #2774e0;
    color: white;
    font-weight: bold;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-table tr:hover {
    background-color: #e6f1ff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .status-badge {
    padding: 5px 10px;
    border-radius: 12px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
  }
  
  .status-active {
    background-color: #4caf50;
    color: white;
  }
  
  .status-inactive {
    background-color: #f44336;
    color: white;
  }
  
  .action-button {
    padding: 6px 12px;
    margin-right: 5px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: bold;
  }
  
  .action-button.edit {
    background-color: #1a73e8;
  }
  
  .action-button.delete {
    background-color: #d9534f;
  }
  
  .action-button:hover {
    opacity: 0.8;
  }
  
  .back-button {
    margin-top: 20px;
    padding: 12px 25px;
    background-color: #1a5bb5;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .back-button:hover {
    background-color: #14549a;
    transform: scale(1.03);
  }