/* Scoped Container */
.incident-report-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 90vh;
}

/* Header */
.incident-report-container h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Form Sections */
.form-section {
  margin-bottom: 20px;
}

.form-section h2 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  text-decoration: underline;
}

/* Form Groups */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group label {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #555;
}

.form-group input,
textarea,
select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

/* Dropdown Styling */
.motivation-dropdown {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  color: #333;
}

/* Actions */
.form-actions {
  display: flex;
  justify-content: space-between;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.close-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #d32f2f;
}