.admin-send-alerts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #2774e0;
    color: white;
    font-family: "Courier New", monospace;
    text-align: center;
    padding: 20px;
  }
  
  .send-alerts-header h2 {
    margin-bottom: 10px;
    font-size: 2.2rem;
    font-weight: bold;
  }
  
  .send-alerts-header p {
    margin-bottom: 20px;
    font-size: 1.2rem;
    max-width: 600px;
  }
  
  .alert-type-selector {
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
    max-width: 600px;
  }
  
  .alert-type-selector label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
  }
  
  .alert-type-selector select {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
  }
  
  .send-alerts-form {
    width: 100%;
    max-width: 600px;
  }
  
  .send-alerts-form textarea {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: none;
    resize: none;
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .char-counter {
    text-align: right;
    font-size: 0.9rem;
    margin-bottom: 15px;
    color: #d0d0d0;
  }
  
  .send-alerts-button {
    width: 100%;
    padding: 12px 0;
    background-color: #1a5bb5;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .send-alerts-button:hover {
    background-color: #144a92;
  }
  
  .back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button:hover {
    background-color: #c9302c;
  }