.admin-layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures footer stays at the bottom */
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  width: 100%;
  background-color: #2774e0;
  font-family: 'Courier New', monospace;
  box-sizing: border-box;
  padding: 0;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1; /* Pushes the footer to the bottom */
}

.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px; /* Restricts width for better alignment */
}

.admin-header {
  text-align: center;
  background-color: #1a5bb5;
  padding: 2rem 2rem;
  border-radius: 20px;
  color: white;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.admin-header h1 {
  margin: 0;
  font-size: 1.8rem;
}

.admin-header h3 {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  font-weight: normal;
}

.admin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
}

.nav-link {
  display: block;
  width: 100%;
  text-align: center;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2774e0;
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

.nav-link.silent-panic {
  background-color: #d9534f; /* Red background for Silent Panic Alarm */
  color: white; /* White text for better contrast */
}

.nav-link.silent-panic:hover {
  background-color: #c9302c; /* Darker red on hover */
  transform: scale(1.03);
}

.big-button {
  font-size: 1.8rem; /* Matches Silent Panic Alarm button */
  font-weight: bold;
  padding: 20px 40px; /* Matches the button size */
  border-radius: 15px; /* Matches the rounded edges */
}

.logout-button {
  width: 100%;
  text-align: center;
  padding: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2774e0;
  background-color: white;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  font-family: 'Courier New', monospace; /* Ensures matching font */
}

.logout-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
}

.footer {
  text-align: center;
  color: #ffffff; /* Set text color */
  font-size: 14px;
  padding: 10px 0;
  margin-top: 20px;
  background-color: transparent; /* Remove background color */
  box-shadow: none; /* Remove shadow if any */
  border: none; /* Ensure no border */
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-header {
    padding: 1.5rem;
  }

  .admin-header h1 {
    font-size: 1.5rem;
  }

  .admin-header h3 {
    font-size: 1rem;
  }

  .nav-link,
  .logout-button {
    font-size: 1rem;
    padding: 1rem;
  }
}