/* Admin Page Container */
.admin-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 20px;
    background-color: #2774e0;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

/* Header Box Styling */
.header-box {
    background-color: #1d5bbf;
    color: white;
    padding: 20px 30px;
    border-radius: 12px;
    text-align: center;
    width: 100%;
    max-width: 500px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.header-box h1,
.header-box h3,
.header-box p {
    color: white;
    margin: 0;
    padding: 5px 0;
}

/* Center Header Text in Incident Reports and View Alerts */
.admin-header {
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
    color: white;
}

/* Button Styling */
.admin-button,
.admin-back-button,
.settings-button,
.back-button {
    background-color: white;
    color: #2774e0;
    padding: 20px 30px;
    font-size: 1.4rem;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin: 20px 0;
    text-decoration: none;
    width: 100%;
    max-width: 500px;
    text-align: center;
    font-weight: bold;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
}

.admin-button:hover,
.admin-back-button:hover,
.settings-button:hover,
.back-button:hover {
    background-color: #f0f0f0;
    transform: scale(1.03);
}

/* Back Button Specific Styling */
.admin-back-button {
    background-color: #d9534f;
    color: white;
}

.admin-back-button:hover {
    background-color: #c9302c;
}

/* Excel Table Box */
.excel-table-box {
    background-color: white;
    padding: 20px 30px;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 900px;
    overflow-x: auto;
    border: 1px solid #ddd;
}

.excel-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
}

.excel-table th,
.excel-table td {
    padding: 12px 15px;
    border: 1px solid #ccc;
    color: #333;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
}

.excel-table th {
    background-color: #2774e0;
    color: white;
    font-weight: bold;
}

.excel-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.excel-table tr:hover {
    background-color: #e6f1ff;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Status Badges */
.status-badge {
    padding: 5px 10px;
    border-radius: 12px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
}

.status-resolved {
    background-color: #4caf50;
    color: white;
}

.status-pending {
    background-color: #ffc107;
    color: black;
}

.status-review {
    background-color: #f44336;
    color: white;
}

/* Action Button */
.action-button {
    padding: 6px 12px;
    background-color: #2774e0;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.action-button:hover {
    background-color: #1d5bbf;
}

/* No Alerts Text */
.no-alerts-text {
    text-align: center;
    color: #555;
    font-size: 16px;
    margin-top: 20px;
}

/* Footer Back Button */
.back-button {
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #2774e0;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    text-align: center;
}

.back-button:hover {
    background-color: #1d5bbf;
}